import React from 'react';
import { Spacer,
  IconButton, Flex, Button, useDisclosure, Drawer,
  DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody,
  Tabs, TabList, Tab, Divider } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useRouterState } from '@tanstack/react-router';
import { useColorModeValue } from '@chakra-ui/react';
import UserMenu from './UserMenu';


const items = [
  { title: 'Configure', path: '/variables' },
  { title: 'Evaluate', path: '/compare' },
  { title: 'Dashboard', path: '/dashboard' },
  // { title: '|', path: null },
  // { title: 'Docs', path: '/docs' },
]

const NavigationBar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuBg = useColorModeValue('ui.light.b3', 'ui.dark.b3')
  const bgColor = useColorModeValue('ui.light.b2', 'ui.dark.b2')
  const textColor = useColorModeValue('ui.light.main', 'ui.dark.main')
  const hoverColor = useColorModeValue('ui.light.main3', 'ui.dark.main3')
  const sepColor = useColorModeValue('ui.light.white', 'ui.dark.white')

  const router = useRouterState();

  const matchRoute = (path:string) => router.location.pathname === path;

  // const crucible_theme = useColorModeValue('ui.light.crucible_theme', 'ui.dark.crucible_theme')
  // const iluvatar_theme = useColorModeValue('ui.light.iluvatar_theme', 'ui.dark.iluvatar_theme')

  const listItems = items.map((item) => (
    <Tab
      as={Link}
      to={item.path}
      className={item.title === "|" ? "pointer-events: none": ""}
      w="100%"
      p={2}
      mx={4}
      key={item.title}
      color={item.title === "|" ? sepColor:textColor}
      onClick={onClose}
      _focus={{boxShadow: 'none'}}
      fontSize="xl"
      _hover={{ color: item.title === "|" ? sepColor:hoverColor}}
      // _selected={{ borderBottom: '2px solid' }}
      borderBottom={matchRoute(item.path) ? '2px solid' : 'none'}
      // borderBottom={matchRoute(item.path) ? '2px solid' : 'none'}
    >
      {item.title}
    </Tab>
  ))

  return (
    <>
      {/* Mobile Menu Button */}
      <IconButton
        aria-label="Open Menu"
        icon={<HamburgerIcon />}
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        position="absolute"
        color={textColor}
        bg={menuBg}
        _hover={{ color: hoverColor }}
        top="1rem"
        right="1rem"
        zIndex="overlay"
      />

      {/* Drawer for Mobile View */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
           color={textColor}
           _hover={{ color: hoverColor }}
          />
          <DrawerBody mt={5} >
            <Flex flexDirection="column" mt="2">
              <Button variant="primary" as={Link} onClick={onClose} to="/variables" w="full" mt="2">Configure</Button>
              <Button variant="primary" as={Link} onClick={onClose} to="/compare" w="full" mt="2">Evaluate</Button>
              <Button variant="primary" as={Link} onClick={onClose} to="/dashboard" w="full" mt="2">Dashboard</Button>
              <Divider borderColor={menuBg} my={5} width={"95%"} />
              <Button variant="primary" as={Link} onClick={onClose} to="/settings" w="full" mt="2">Settings</Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Horizontal Navigation for Desktop */}
      <Flex
        as="nav"
        p={4}
        bg={bgColor}
        alignItems="center"
        position="sticky"
        top={0}
        left={0}
        right={0}
        zIndex="docked"
        height="10vh"
        width="100%"
        display={{ base: 'none', md: 'flex' }}
      >

        {/* Button on the far left */}
        {/* <Link to="/" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Flex cursor="pointer" maxH="100%" aspectRatio={1} mr={20}>
            <Image maxH="100%" src={Logo} alt="Logo" />
            <Flex flexDirection="column" justifyContent="center" ml="2">
              <Text color={iluvatar_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">iluvatar</Text>
              <Text color={crucible_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">Crucible</Text>
            </Flex>
          </Flex>
        </Link>  */}

        {/* Spacer to push everything to the center and ends */}
        <Spacer />

        {/* <Flex gap="2rem" justifyContent="center" >
          {listItems}
        </Flex> */}

        <Tabs
          isFitted={true}
          variant="unstyled"
          ml="auto"
          >
          <TabList>
            {listItems}
          </TabList>
        </Tabs>

        <Spacer />

        {/* Button on the far right */}
        <UserMenu />

      </Flex>
    </>
  );
};

export default NavigationBar;
