import React from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaUserAstronaut } from 'react-icons/fa'
import { FiLogOut, FiSettings } from 'react-icons/fi'

import useAuth from '../../hooks/useAuth'
import { Link } from '@tanstack/react-router'

const UserMenu: React.FC = () => {
  const { logout } = useAuth()

  const handleLogout = async () => {
    logout()
  }

  const bgColor = useColorModeValue('ui.light.main', 'ui.dark.main')
  const hoverColor = useColorModeValue('ui.light.main2', 'ui.dark.main3')
  const astronautColor = useColorModeValue('ui.light.btnText', 'ui.dark.btnText')

  return (
    <>
      {/* Desktop */}
      <Box
        display={{ base: 'none', md: 'block' }}
        top={4}
        right={4}
        px={4}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            color={astronautColor} 
            icon={<FaUserAstronaut fontSize="18px" />}
            bg={bgColor}
            _hover={{ bg: hoverColor }}
            _active={{ bg: hoverColor }}
            isRound
          />
          <MenuList>
            <MenuItem
            icon={<FiSettings fontSize="18px" />}
            as={Link}
            to="settings"
            color={bgColor}
            >
              Settings
            </MenuItem>
            <MenuItem
              icon={<FiLogOut fontSize="18px" />}
              onClick={handleLogout}
              fontWeight="bold"
              color={bgColor}
            >
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}

export default UserMenu
