import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the type for the context state
interface ExperimentContextType {
  experimentId: string;
  setExperimentId: (id: string) => void;
}

// Create the context with an initial undefined type, which will be set on the provider
export const ExperimentContext = createContext<ExperimentContextType | undefined>(undefined);

// Define the provider props type
interface ExperimentProviderProps {
  children: ReactNode;
}

export const ExperimentProvider: React.FC<ExperimentProviderProps> = ({ children }) => {
  const [experimentId, setExperimentId] = useState<string>('');

  return (
    <ExperimentContext.Provider value={{ experimentId, setExperimentId }}>
      {children}
    </ExperimentContext.Provider>
  );
};

// Custom hook to use the experiment context
export const useExperiment = (): ExperimentContextType => {
  const context = useContext(ExperimentContext);
  if (!context) {
    throw new Error('useExperiment must be used within a ExperimentProvider');
  }
  return context;
};
