import React, { useState } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { ApiError, ModelCreate, ModelsService } from '../../client'
import useCustomToast from '../../hooks/useCustomToast'

interface AddModelProps {
  isOpen: boolean
  onClose: () => void
}

const AddModel: React.FC<AddModelProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const showToast = useCustomToast()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ModelCreate>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      description: '',
      openai_name: '',
      api_key: '',
      base_url: '',
      internal_model_id: null,
    },
  })

  const addModel = async (data: ModelCreate) => {
    if (selectedModel.name === 'custom') {
      await ModelsService.createModel({ requestBody: data, experimentId: localStorage.getItem('experiment_id') || ''})
    }
    else {
      await ModelsService.createModel({ requestBody: selectedModel, experimentId: localStorage.getItem('experiment_id') || ''})
    }
  }
  const modelsQuery = useQuery('internal_models', () => ModelsService.readInternalModels({}))
  const formData = modelsQuery.data
  if (modelsQuery.isError) {
    const errDetail = (modelsQuery.error as ApiError).body?.detail
    showToast('Something went wrong.', `${errDetail}`, 'error')
  }

  const mutation = useMutation(addModel, {
    onSuccess: () => {
      showToast('Success!', 'Model created successfully.', 'success')
      reset()
      onClose()
    },
    onError: (err: ApiError) => {
      const errDetail = err.body.detail
      showToast('Something went wrong.', `${errDetail}`, 'error')
    },
    onSettled: () => {
      queryClient.invalidateQueries('models')
    },
  })

  const onSubmit: SubmitHandler<ModelCreate> = (data) => {
    mutation.mutate(data)
  }

  const background = useColorModeValue('ui.light.b2', 'ui.dark.b2')

  const [model, setModel] = useState('')
  const [selectedModel, setInternalModel] = useState({
      name: 'custom', 
      description: '', 
      openai_name: '', 
      api_key: '', 
      base_url: '',
      internal_model_id: ''
    })

  const handleModelChange = (event: any) => {
    if (formData === undefined) {
      return
    }
    const formDataData = formData.data
    const selectedOption = formDataData.find(
      item => item.openai_name === event.target.value
    ) || {
      name: 'custom', 
      description: '', 
      openai_name: '', 
      api_key: '', 
      base_url: '',
      id: ''
    }
    setModel(selectedOption.name)
    setInternalModel({
      "name": selectedOption.name, 
      "description": selectedOption.description || "", 
      "openai_name": selectedOption.openai_name, 
      "api_key": selectedOption.api_key, 
      "base_url": selectedOption.base_url,
      "internal_model_id": selectedOption.id
    })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'sm', md: 'md' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          bg={background}
          >
          <ModalHeader>Add Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Model</FormLabel>
              <Select placeholder="Select model" onChange={handleModelChange}>
                {formData && formData.data.map((item, index) => (
                    <option key={index} value={item.openai_name}>
                      <Input type="hidden" id="name" value={item.name} />
                      <Input type="hidden" id="description" value={item.description || ""} />
                      <Input type="hidden" id="openai_name" value={item.openai_name} />
                      <Input type="hidden" id="api_key" value={item.api_key} />
                      <Input type="hidden" id="base_url" value={item.base_url} />
                      {item.name}
                    </option>
                  ))}
                <option value="custom">Custom Model</option>
              </Select>
            </FormControl>
            {model === 'custom' && ( <>
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel htmlFor="title">Name</FormLabel>
                <Input
                  id="name"
                  {...register('name', {
                    required: 'Name is required.',
                  })}
                  placeholder="Name"
                  type="text"
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Input
                  id="description"
                  {...register('description')}
                  placeholder="Description"
                  type="text"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel htmlFor="openai_name">Open AI Name</FormLabel>
                <Input
                  id="openai_name"
                  {...register('openai_name')}
                  placeholder="Open AI Name"
                  type="text"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel htmlFor="api_key">API Key</FormLabel>
                <Input
                  id="api_key"
                  {...register('api_key')}
                  placeholder="API Key"
                  type="text"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel htmlFor="base_url">Base URL</FormLabel>
                <Input
                  id="base_url"
                  {...register('base_url')}
                  placeholder="Base URL"
                  type="text"
                />
              </FormControl>
            </>
            )}
          </ModalBody>

          <ModalFooter gap={3}>
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddModel
