import React from 'react'
import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

import AddUser from '../Admin/AddUser'
import AddModel from '../Items/AddModel'
import AddPrompt from '../Items/AddPrompt'
import SubmitExperiment from '../Items/SubmitExperiment'

interface AddEntityBtnProps {
  type: string
  numModels?: number
  numPrompts?: number
}


const AddEntityBtn: React.FC<AddEntityBtnProps> = ({ type, numModels, numPrompts }) => {
  const addUserModal = useDisclosure()
  const addModelModal = useDisclosure()
  const addPromptModal = useDisclosure()
  const submitExperimentModal = useDisclosure()

  function customOpenModal(modalType: string) {
    if (modalType === 'User') {
      addUserModal.onOpen();
    } else if (modalType === 'Model') {
      addModelModal.onOpen();
    } else if (modalType === 'Prompt') {
      addPromptModal.onOpen();
    } else if (modalType === 'Submission') {
      submitExperimentModal.onOpen();
    }
  }

  function canSubmit(): boolean {
    if (numModels && numModels >= 1 && numPrompts && numPrompts >= 2) {
      return true;
    }
    else if (numModels && numModels >= 2 && numPrompts && numPrompts >= 1) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Flex py={8}>
        {/* TODO: Complete search functionality */}
        {/* <InputGroup w={{ base: '100%', md: 'auto' }}>
                    <InputLeftElement pointerEvents='none'>
                        <Icon as={FaSearch} color='gray.400' />
                    </InputLeftElement>
                    <Input type='text' placeholder='Search' fontSize={{ base: 'sm', md: 'inherit' }} borderRadius='8px' />
                </InputGroup> */}
        { type === "Submission" && (<Button
          variant="primary"
          gap={1}
          fontSize={{ base: 'sm', md: 'inherit' }}
          onClick={() => customOpenModal(type)}
          // isDisabled={!canSubmit()}
        >
           Submit Experiment
        </Button>) }
        { localStorage.getItem('experiment_live') === 'true' && type !== "Submission" && ( <Button
          variant="primary"
          // gap={1}
          fontSize={{ base: 'sm', md: 'inherit' }}
          onClick={() => customOpenModal(type)}
          // canSumbit() ? isDisabled : null
        >
          <Icon as={FaPlus} /> Add {type}
        </Button>)}
        <AddUser isOpen={addUserModal.isOpen} onClose={addUserModal.onClose} />
        { localStorage.getItem('experiment_live') === 'true' && ( <>
        <AddModel isOpen={addModelModal.isOpen} onClose={addModelModal.onClose} />
        <AddPrompt isOpen={addPromptModal.isOpen} onClose={addPromptModal.onClose} />
        </>)}
        <SubmitExperiment 
          isOpen={submitExperimentModal.isOpen} 
          onClose={submitExperimentModal.onClose}
          canSubmit={canSubmit}
        />
      </Flex>
    </>
  )
}

export default AddEntityBtn
