import React, { useEffect, useState } from 'react'
import { Box,
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Input } from '@chakra-ui/react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { BsThreeDots } from 'react-icons/bs'
import type { ExperimentsOut } from '../../client';
import { useQueryClient } from 'react-query';
import { ExperimentsService } from '../../client'

// import { UserOut } from '../../client'

// const items = [
//   { icon: FiHome, title: 'Dashboard', path: '/' },
//   { icon: FiBriefcase, title: 'Items', path: '/variables' },
//   { icon: FiSettings, title: 'User Settings', path: '/settings' },
// ]

interface ExperimentsProps {
  experiments: ExperimentsOut | undefined;
}


const Experiments: React.FC<ExperimentsProps> = ({experiments}) => {
  const textColor = useColorModeValue('ui.light.main', 'ui.dark.main')
  const hoverColor = useColorModeValue('ui.light.main2', 'ui.dark.main3')
  const activeBg = useColorModeValue('ui.light.b2', 'ui.dark.b2')

  const queryClient = useQueryClient()
  const [changed, setChanged] = useState(false);
  const [newName, setNewName] = useState('')
  const [renameExperimentId, setRenameExperimentId] = useState('')

  const handleOpenRename = (experimentName: string, experimentId: string) => {
    setNewName(experimentName)
    setRenameExperimentId(experimentId)
  }

  // const handleCloseRenameModal = () => {
  //   setIsRenameModalOpen(false)
  // }

  const handleRename = async () => {
    await ExperimentsService.updateExperiment({ experimentId: renameExperimentId, requestBody: { name: newName } })
    queryClient.invalidateQueries('experiments')
    setRenameExperimentId('')
    // handleCloseRenameModal()
  }

  // const { refetch } = useQuery('experiments', () => ExperimentsService.getExperimentStatus({id: localStorage.getItem('experiment_id') || ''}))

  // const currentUser = queryClient.getQueryData<UserOut>('currentUser')
  // const { data: experiments } = useQuery('experiments', () => ExperimentsService.readExperiments({}))

  const changeLocalStorage = async (experimentId: string) => {
    localStorage.setItem('experiment_id', experimentId)
    const experiment_live = await ExperimentsService.getExperimentStatus({id: experimentId})
    localStorage.setItem('experiment_live', experiment_live.toString())
    queryClient.invalidateQueries('prompts')
    queryClient.invalidateQueries('models')
    queryClient.invalidateQueries('evaluate')
    queryClient.invalidateQueries('dashboard')
    setChanged(true)

  }

  const handleDeleteExperiment = async (experimentId: string) => {
    await ExperimentsService.deleteExperiment({id: experimentId})
    queryClient.invalidateQueries('experiments')
    // const newExperimentId
    if (experimentId === localStorage.getItem('experiment_id')) {
      const newExperimentId = experiments?.data.filter(experiment => experiment.id !== experimentId)[0].id
      localStorage.setItem('experiment_id', newExperimentId || '')
      queryClient.invalidateQueries('prompts')
      queryClient.invalidateQueries('models')
      queryClient.invalidateQueries('evaluate')
      queryClient.invalidateQueries('dashboard')
    }
    // localStorage.setItem('experiment_id', experiments.data[0].id || '')
    setChanged(true)
  }

  const canDelete = () => {
    return experiments?.data.length !== 1
  }

  useEffect(() => {
    if (changed) {
      // refetch()
      setChanged(false);
    }
  }, [changed]);

  const listItems = experiments && experiments.data.sort(
    function(a, b) {
      return b.ordinal! - a.ordinal!;
    }
  ).map((experiment) => (
    <Flex
      w="100%"
      roundedLeft={10}
      // maxWidth={"70%"}
      py={2}
      key={experiment.name}
      color={
        experiment.id === localStorage.getItem('experiment_id') ?
        textColor : textColor
      }
      bg={experiment.id === localStorage.getItem('experiment_id') ? activeBg : 'transparent'}
      alignItems="center"
      justifyContent="space-between"
    >
      {renameExperimentId !== experiment.id ?
      <Text
        onClick={() => changeLocalStorage(experiment.id)}
        _hover={{ cursor: 'pointer', color: hoverColor }}
        isTruncated maxWidth={"70%"} ml={2}>{experiment.name}
      </Text> :
      <Input
        // onClick={() => changeLocalStorage(experiment.id)}
        // _hover={{ cursor: 'pointer', color: hoverColor }}
        isTruncated maxWidth={"70%"} ml={2} value={newName} onChange={(e) => setNewName(e.target.value)} onBlur={() => handleRename()}
      /> }
      <Flex marginLeft="auto">
        <Menu>
          <MenuButton
            as={IconButton}
            _hover={{ cursor: 'pointer', color: hoverColor }}
            aria-label='Options'
            icon={<BsThreeDots />}
            variant='styleless'
            flexDirection={'row-reverse'}
          />
          <MenuList>
            <MenuItem
              icon={<FiEdit />}
              _hover={{ color: hoverColor }}
              // command='⌘T'
              onClick={() => handleOpenRename(experiment.name || '', experiment.id)}
              >
              Rename
            </MenuItem>
            <MenuItem
              icon={<FiTrash />}
              _hover={{ color: hoverColor }}
              // command='⌘N'
              onClick={() => handleDeleteExperiment(experiment.id)}
              isDisabled={!canDelete()}
              >
              Delete Experiment
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {/* <Modal isOpen={isRenameModalOpen} onClose={handleCloseRenameModal}>
        <ModalOverlay />
        <ModalContent bg={activeBg}>
          <ModalHeader>Rename Experiment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input value={newName} onChange={(e) => setNewName(e.target.value)} placeholder="Enter new name" />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" mr={3} onClick={handleRename}>
              Rename
            </Button>
            <Button variant="ghost" onClick={handleCloseRenameModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Flex>
  ))

  return ( experiments &&
    <>
      <Box w="100%" maxW="250px" overflow="hidden">{listItems}</Box>
    </>
  )
}

export default Experiments
