import { Box, Text, Progress, Flex } from '@chakra-ui/react';

interface Milestone {
  label: string;
  value: number;
}

interface MilestoneProgressBarProps {
  milestones: Milestone[];
  currentValue: number;
  maxValue: number;
}

const MilestoneProgressBar: React.FC<MilestoneProgressBarProps> = ({ milestones, currentValue, maxValue }) => {

  return (
    <Flex width={"100%"} px={10}>
      <Box width="70%" m={10} display={{ base: 'none', md: 'block' }}>
        <Progress rounded={5} value={currentValue} max={maxValue} size="md" colorScheme="blue" />
        <Box position="relative" mt={2}>
          {milestones.map((milestone, index) => {
            // Calculate left offset as a percentage
            const leftOffset = `${milestone.value}%`;

            return (
              <Text
                display={{ base: 'none', md: 'block' }}
                key={index}
                textAlign="center"
                // width="100%"
                left={leftOffset}
                transform="translateX(-50%)"
                position="absolute"
                _before={{
                  content: `" "`,
                  height: "20px",
                  width: "2px",
                  backgroundColor: currentValue >= milestone.value ? "blue.500" : "gray.300",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  top: "-20px"
                }}
              >
                {milestone.label}
              </Text>
            );
          })}
        </Box>
      </Box>
      <Text
        my={"auto"}
        ml={5}
        width={{ base: '100%', md: '30%' }}>
          {currentValue === 0 ? "-/-" : currentValue+"/"+maxValue} Comparisons Completed
      </Text>
    </Flex>
  );
};

export default MilestoneProgressBar;
