import React from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { ApiError, PromptCreate, PromptsService } from '../../client'
import useCustomToast from '../../hooks/useCustomToast'

interface AddPromptProps {
  isOpen: boolean
  onClose: () => void
}

const AddPrompt: React.FC<AddPromptProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const showToast = useCustomToast()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<PromptCreate>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      text: ''
    },
  })

  const addPrompt = async (data: PromptCreate) => {
    await PromptsService.createPrompt({ requestBody: data, experimentId: localStorage.getItem('experiment_id') || '' })
  }

  const mutation = useMutation(addPrompt, {
    onSuccess: () => {
      showToast('Success!', 'Prompt created successfully.', 'success')
      reset()
      onClose()
    },
    onError: (err: ApiError) => {
      const errDetail = err.body.detail
      showToast('Something went wrong.', `${errDetail}`, 'error')
    },
    onSettled: () => {
      queryClient.invalidateQueries('prompts')
    },
  })

  const onSubmit: SubmitHandler<PromptCreate> = (data) => {
    mutation.mutate(data)
  }

  const background = useColorModeValue('ui.light.b2', 'ui.dark.b2')

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'sm', md: 'md' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          bg={background}
          >
          <ModalHeader>Add Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired isInvalid={!!errors.text}>
              <FormLabel htmlFor="title">Name</FormLabel>
              <Input
                id="text"
                {...register('text', {
                  required: 'Prompt is required.',
                })}
                placeholder="Name"
                type="text"
              />
              {errors.text && (
                <FormErrorMessage>{errors.text.message}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddPrompt
