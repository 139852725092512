import { Text } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/_layout/fake')({
    component: Fake,
})

function Fake () {
  console.log(import.meta.env.VITE_FRONTEND_SENTRY_DSN);
  console.log(import.meta.env.VITE_API_URL);
  console.log(process.env.NODE_ENV);
  throw new Error("Testing Sentry - Frontend Version");
  return (
    <>
      <Text>
        Fake Page
      </Text>
    </>
  );
};

export default Fake;
