import { useState } from 'react'
import {
  Container,
  Flex,
  Heading,
  Spinner,
  Box,
  Grid,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  CloseButton,
  Textarea,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image
} from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'
import { useQuery, useQueryClient } from 'react-query'
import { useColorModeValue } from '@chakra-ui/react'

import { ApiError, ModelsService, PromptsService } from '../../client'
// import ActionsMenu from '../../components/Common/ActionsMenu'
import AddEntityBtn from '../../components/Common/AddEntityBtn'
import useCustomToast from '../../hooks/useCustomToast'
import { UsersService } from '../../client'
import ModelDropdown from '../../components/Common/ModelDropdown'
import { LogoMap } from '../../utils'

export const Route = createFileRoute('/_layout/variables')({
  component: Variables,
})

function Variables() {
  const showToast = useCustomToast()
  const queryClient = useQueryClient()
  const promptsQuery = useQuery('prompts', () => PromptsService.readPrompts({experimentId: localStorage.getItem('experiment_id') || ''}))
  const modelsQuery = useQuery('models', () => ModelsService.readModels({experimentId: localStorage.getItem('experiment_id') || ''}))

  if (modelsQuery.isError) {
    const errDetail = (modelsQuery.error as ApiError).body?.detail
    showToast('Something went wrong.', `${errDetail}`, 'error')
  } else if (promptsQuery.isError) {
    const errDetail = (promptsQuery.error as ApiError).body?.detail
    showToast('Something went wrong.', `${errDetail}`, 'error')
  }

  const models = modelsQuery.data
  const prompts = promptsQuery.data

  const bgColor = useColorModeValue('ui.light.b2', 'ui.dark.b2')
  const borderColor = useColorModeValue('ui.light.b3', 'ui.dark.b3')
  const selectableColor = useColorModeValue('ui.light.main', 'ui.dark.main')

  const internalModelsQuery = useQuery('internal_models', () => ModelsService.readInternalModels({}))
  const internalData = internalModelsQuery.data

  const [selectedModel, setSelectedModel] = useState('')
  const [currentPrompt, setCurrentPrompt] = useState('')


  const handleModelChange = async (event: any) => {
    if (internalData === undefined) {
      return
    }
    console.log("event", event)
    const selectedOption = internalData.data.find(
      item => item.openai_name === event.openai_name
    ) || { name: 'custom', description: '', openai_name: '', api_key: '', base_url: '', id: '' }
    const modelRequestBody = {
      "name": selectedOption.name,
      "description": selectedOption.description || "",
      "openai_name": selectedOption.openai_name,
      "api_key": selectedOption.api_key,
      "base_url": selectedOption.base_url,
      "internal_model_id": selectedOption.id,
    }
    setSelectedModel('')
    await ModelsService.createModel({
      requestBody: modelRequestBody,
      experimentId: localStorage.getItem('experiment_id') || ''})
    queryClient.invalidateQueries('models')
  }

  const handlePromptChange = async (event: any) => {
    const promptRequestBody = {"text": event.target.value}
    setCurrentPrompt('')
    if  (!(promptRequestBody.text === '' || promptRequestBody.text === undefined)) {
      await PromptsService.createPrompt({ requestBody: promptRequestBody, experimentId: localStorage.getItem('experiment_id') || ''})
    }
    queryClient.invalidateQueries('prompts')
  }

  const handleModelDelete = async (id: string) => {
    await ModelsService.deleteModel({ id: id })
    queryClient.invalidateQueries('models')
  }

  const handlePromptDelete = async (id: string) => {
    await PromptsService.deletePrompt({ id: id })
    queryClient.invalidateQueries('prompts')
  }

  const handlePromptUpdate = async (event: any, id: string) => {
    const promptRequestBody = {"text": event.target.value}
    await PromptsService.updatePrompt({ promptId: id, requestBody: promptRequestBody })
  }

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
  const [request, setRequest] = useState('')

  const handleOpenRequestModal = () => {
    setIsRequestModalOpen(true)
  }

  const handleCloseRequestModal = () => {
    setIsRequestModalOpen(false)
  }

  const handleRequest = () => {
    UsersService.featureRequest({ featureRequest: request })
    handleCloseRequestModal()
  }

  function getInternalModelEquivalent(openai_name: string){
    // temp function until we can figure out why theres a difference with internal models
    console.log(internalData)
    console.log(openai_name)
    return internalData?.data.find(
      item => item.openai_name === openai_name
    ) ?? undefined
  } 

  function capitalize(str:string){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      {(modelsQuery.isLoading || promptsQuery.isLoading )? (
        // TODO: Add skeleton
        <Flex justify="center" align="center" height="100vh" width="full">
          <Spinner size="xl" color="ui.main" />
        </Flex>
      ) : (
        models && prompts && (
          <Container maxW="full"
            // bg={contentBg}
            // rounded={"lg"}
          >
          <Heading size="lg" textAlign={{ base: 'center', md: 'left' }}
            display={{ base: 'inherit', md: 'none' }}
            mb={8}>
            Configure
          </Heading>
          <Accordion bg={borderColor} defaultIndex={[1,2]} allowMultiple mt={4}
            rounded="lg">
            <AccordionItem>
              <h2>
                <AccordionButton justifyContent="space-between" alignItems="center">
                  <Heading size="md" textAlign={{ base: 'center', md: 'left' }} pt={2}>
                    What is an experiment?
                  </Heading>
                  <Flex alignItems="center">
                    <AccordionIcon color={selectableColor} ml={2} />
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                AI behaviors and outcomes can be wildly different based on both the underlying model and the command (prompt) being issued to them. These two factors -- models and prompts -- also interact in non-intuitive ways. Crucible lets you experiment with both and find the best pairing.
                <br></br><br></br>
                Every prompt will be run against every model six times and you will be able to compare the outputs. At the end, you will get a dashboard summarizing the best model + prompt combo for your use case.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton justifyContent="space-between" alignItems="center">
                  <Heading size="md" textAlign={{ base: 'center', md: 'left' }} pt={2}>
                    Models
                  </Heading>
                  <Flex alignItems="center">
                    <AccordionIcon color={selectableColor} ml={2} />
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {/* <AddEntityBtn type={'Model'} /> */}
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={6}>
                  {models.data.map((model) => (
                  <Box
                    position="relative"
                    key={model.id}
                    p={5}
                    bg={bgColor}
                    shadow="md"
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor={borderColor}
                    overflow="hidden" // Ensures nothing spills out of the boundaries
                  >
                    {localStorage.getItem('experiment_live') === 'true' && (
                      <CloseButton
                        size="sm"
                        onClick={() => handleModelDelete(model.id)}
                        position="absolute"
                        color={selectableColor}
                        top={2}
                        right={2}
                        aria-label="Delete model"
                      />
                    )}
                    <Flex align="center" mb={3} mr={3}>
                      <Image
                        boxSize="50px"
                        objectFit="scale-down"
                        src={
                          getInternalModelEquivalent(model.openai_name)?.company 
                          ? LogoMap(getInternalModelEquivalent(model.openai_name)!.company) 
                          : ""  
                        } // Make sure to replace this with actual image paths or dynamic paths from your data
                        mr={4}
                        alt={``}
                      />
                      <Box flex="1">
                        <Text fontWeight="bold" fontSize="md">{model.name}</Text>
                        <Text fontSize="sm">{capitalize(getInternalModelEquivalent(model.openai_name)?.company ?? "")}</Text>
                      </Box>
                    </Flex>
                    <Text fontSize="sm">{
                      getInternalModelEquivalent(model.openai_name)?.cost ?
                      "$" + getInternalModelEquivalent(model.openai_name)!.cost + " | " + getInternalModelEquivalent(model.openai_name)!.parameter_count + " parameters" : "Deprecated Model"
                      }</Text>
                  </Box>
                ))}
                  {localStorage.getItem('experiment_live') === 'true' && (
                    <Box position={"relative"} p={5} bg={bgColor} shadow="md" borderWidth="1px" borderRadius="lg" borderColor={borderColor}>
                      <Text mb={3} fontWeight="bold">Add Model</Text>
                      {/* <Select placeholder="Select model" textColor={selectableColor} onChange={handleModelChange} value={selectedModel}> */}
                      <ModelDropdown
                        models={internalData?.data ?? [{
                          name: "Error Loading",
                          openai_name: "Error Loading",
                          cost: 0,
                          parameter_count: "Error Loading",
                          company: "Error Loading",
                        }]}
                        selectedModel={selectedModel}
                        onModelChange={handleModelChange}
                      />
                      {/* </Select> */}
                    </Box>
                  )}
                </Grid>

              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton justifyContent="space-between" alignItems="center">
                  <Heading size="md" textAlign={{ base: 'center', md: 'left' }} pt={2}>
                    Prompts
                  </Heading>
                  <Flex alignItems="center">
                    <AccordionIcon color={selectableColor} ml={2} />
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {/* <AddEntityBtn type={'Prompt'} /> */}
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                  {prompts.data.map((prompt) => (
                    <Box position={"relative"} key={prompt.id} p={5} bg={bgColor} shadow="md" borderWidth="1px" borderRadius="lg" borderColor={borderColor}>
                      {localStorage.getItem('experiment_live') === 'true' && (
                        <CloseButton
                          size="sm"
                          onClick={() => handlePromptDelete(prompt.id)}
                          position="absolute"
                          color={selectableColor}
                          top={0}
                          right={0}
                          aria-label="Delete model"
                        />
                      )}
                      {(localStorage.getItem('experiment_live')) === 'true' ?
                        <Textarea textColor={selectableColor} resize={"vertical"} onBlur={e => handlePromptUpdate(e, prompt.id)}>{prompt.text}</Textarea> :
                        <Textarea resize={"vertical"} borderStyle={"hidden"} isReadOnly>{prompt.text}</Textarea>
                      }

                    </Box>
                  ))}
                  {localStorage.getItem('experiment_live') === 'true' && (
                    <Box position={"relative"} p={5} bg={bgColor} shadow="md" borderWidth="1px" borderRadius="lg" borderColor={borderColor}>
                      <Textarea _placeholder={{color:selectableColor}} textColor={selectableColor} value={currentPrompt} resize={"vertical"} placeholder='Add a new prompt' onChange={(e) => setCurrentPrompt(e.target.value)} onBlur={e => handlePromptChange(e)}/>
                    </Box>
                  )}
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Text fontSize={13}>
            Need more fine-grained control? Want to run collaborative experiments with your organization? <Link color={selectableColor} onClick={handleOpenRequestModal}> Make a request</Link>
          </Text>
          <Modal isOpen={isRequestModalOpen} onClose={handleCloseRequestModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Submit a feature request</ModalHeader>
              <ModalCloseButton />
              <ModalBody minW="400px" minH="400px">
              <Textarea
                value={request}
                width="100%"
                minW="400px"
                minH="400px"
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Enter your Request"
                size="lg" // make the textarea larger
              />
              </ModalBody>
              <ModalFooter>
                <Button variant="primary" mr={3} onClick={handleRequest}>
                  Submit
                </Button>
                <Button variant="ghost" onClick={handleCloseRequestModal}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          { localStorage.getItem('experiment_live') === 'true' && (
          <Flex justifyContent="center" alignItems="center" mt={4}>
            <AddEntityBtn type={'Submission'} numModels={models.data.length} numPrompts={prompts.data.length} />
          </Flex>
          )}
        </Container>
        )
      )}
    </>
  )
}

export default Variables
