/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardStats } from '../models/DashboardStats';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * Get Dashboard Stats
     * Route for getting dashboard stats for the current user and the current experiment.
     *
     * Args:
     * session (SessionDep): The database session.
     * current_user (CurrentUser): The current user.
     * experiment_id (CurrentExperiment): The current experiment.
     *
     * Returns:
     * DashboardStats | None: Stats for the dashboard of current experiment.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getDashboardStats({
        experimentId,
    }: {
        experimentId: string,
    }): CancelablePromise<(DashboardStats | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboard_stats/',
            headers: {
                'experiment-id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
