import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import Logo from '../../assets/images/logo_dark.svg';
import '@fontsource/comfortaa'

const LogoText: React.FC = () => {
    const crucible_theme = useColorModeValue('ui.light.crucible_theme', 'ui.dark.crucible_theme')
    const iluvatar_theme = useColorModeValue('ui.light.dark', 'ui.dark.iluvatar_theme')

    return (
        <>
            <Flex maxH="100%" aspectRatio={1} mr={20}>
                <Image 
                    filter="drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))"
                    maxH="100%" src={Logo} alt="Logo" />
                <Flex flexDirection="column" justifyContent="center" ml="2"> {/* Ensure this height matches the logo height */}
                    <Text color={iluvatar_theme} fontSize="5xl" fontFamily="Comfortaa" lineHeight="1">iluvatar</Text>
                    <Text color={crucible_theme} fontSize="5xl" fontFamily="Comfortaa" lineHeight="1">Crucible</Text>
                </Flex>
            </Flex>
        </>
    )
}

export default LogoText;
