import React, { ReactNode } from 'react';
import { ExperimentProvider } from './ExperimentContext';

interface CombinedProviderProps {
  children: ReactNode;
}

const CombinedProvider: React.FC<CombinedProviderProps> = ({ children }) => {
  return (
      <ExperimentProvider>
          {children}
      </ExperimentProvider>
  );
};

export default CombinedProvider;
