import { createFileRoute } from '@tanstack/react-router'
import { Text } from '@chakra-ui/react'
import { useState, useEffect } from 'react';


import { UsersService } from '../../client'

// TODO: redirect to 404 unless superuser
export const Route = createFileRoute('/_layout/create_referral')({
  component: Create_Referral,
})

function Create_Referral() {
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    UsersService.createReferral({ email: "adsad" })
        .then(link => {
            setReferralLink(link);
        })
        .catch(error => {
            console.error('Failed to fetch referral link:', error);
        });
  }, []); // Empty dependency array means this runs once on component mount


  return (
    <>
      <Text>{referralLink ? referralLink : "Loading"}</Text>
    </>
  )
}

export default Create_Referral
