import React from 'react';
import { Flex, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

interface HeaderWithTooltipProps {
  headerText: string;
  tooltipLabel: string;
}

const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = ({ headerText, tooltipLabel }) => {
  return (
    <Flex align="center">
      <Text fontSize="xl" fontWeight={"bold"} mr={2}>{headerText}</Text>
      <Tooltip label={tooltipLabel} fontSize="md" placement="right" hasArrow>
        <IconButton
          aria-label="More Info"
          icon={<InfoOutlineIcon />}
          variant="ghost"
          size="sm"
        />
      </Tooltip>
    </Flex>
  );
};

export default HeaderWithTooltip;
