import React, { useState } from 'react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
  Button,
  Divider,
  Switch,
  useColorMode,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Textarea
} from '@chakra-ui/react'
import { MoonIcon } from '@chakra-ui/icons'
import { FiLogOut, FiMenu } from 'react-icons/fi'
import { useQueryClient, useQuery } from 'react-query'

import { UsersService } from '../../client'
import Logo from '../../assets/images/logo_dark.svg'
import { UserOut } from '../../client'
import useAuth from '../../hooks/useAuth'
import Experiments from './Experiments'
import { ExperimentsService } from '../../client'
import AddExperiment from '../Items/AddExperiment'
import { Link } from '@tanstack/react-router'
import '@fontsource/comfortaa'

const ExperimentBar: React.FC = () => {
  const queryClient = useQueryClient()

  const { colorMode, toggleColorMode } = useColorMode()

  const bgColor = useColorModeValue('ui.light.b1', 'ui.dark.b1')
  const hamburgerBg = useColorModeValue('ui.light.b3', 'ui.dark.b3')
  const dividerBg = useColorModeValue('ui.light.dark', 'ui.dark.b3')
  // const unselectableTextColor = useColorModeValue('ui.light.dark', 'ui.dark.white')
  // const dangerColor = useColorModeValue('ui.light.danger', 'ui.dark.danger')
  const selectableColor = useColorModeValue('ui.light.main', 'ui.dark.main')
  const hoverColor = useColorModeValue('ui.light.main3', 'ui.dark.main3')
  const iluvatar_theme = useColorModeValue('ui.light.dark', 'ui.dark.iluvatar_theme')
  const crucible_theme = useColorModeValue('ui.light.crucible_theme', 'ui.dark.crucible_theme')

  const currentUser = queryClient.getQueryData<UserOut>('currentUser')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const experimentModal = useDisclosure()
  const { logout } = useAuth()

  const handleLogout = async () => {
    logout()
  }
  const { data: experiments, refetch } = useQuery('experiments', () => ExperimentsService.readExperiments({}))
  const experimentClicked = () => {
    experimentModal.onClose();
    refetch();
  }

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [feedback, setFeedback] = useState('')

  const handleOpenFeedbackModal = () => {
    setIsFeedbackModalOpen(true)
  }

  const handleCloseFeedbackModal = () => {
    setIsFeedbackModalOpen(false)
  }

  const handleFeedback = () => {
    UsersService.feedback({ feedback })
    handleCloseFeedbackModal()
  }

  return (
    <>
      {/* Mobile */}
      <IconButton
        onClick={onOpen}
        display={{ base: 'flex', md: 'none' }}
        aria-label="Open Menu"
        position="absolute"
        fontSize="20px"
        m={4}
        icon={<FiMenu />}
        bg={hamburgerBg}
        color={selectableColor}
        _hover={{ color: hoverColor }}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxW="250px">
          <DrawerCloseButton
           color={selectableColor}
           _hover={{ color: hoverColor }}
          />
          <DrawerBody py={8}>
            <Flex flexDir="column" justify="space-between">
              <Box>
                <Link to="/"style={{ display: 'flex', alignItems: 'center', height:'10vh'}}>
                  <Flex cursor="pointer" maxH="100%" aspectRatio={1} mr={20}>
                    <Image
                      filter="drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))"
                      maxH="80%" src={Logo} alt="Logo" my={"auto"} />
                    <Flex flexDirection="column" justifyContent="center" ml="2"> {/* Ensure this height matches the logo height */}
                      <Text
                        color={iluvatar_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">iluvatar</Text>
                      <Text
                        color={crucible_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">Crucible</Text>
                    </Flex>
                  </Flex>
                </Link>
                <Divider borderColor={dividerBg} my={5} width={"95%"} />
                <Box>
                  <Flex
                  flexDir="row"
                  justify="space-between"
                  align="center"
                  >
                  <Text fontSize="xl">Experiments</Text>
                  <Button
                    variant="primary"
                    onClick={() => experimentModal.onOpen()}
                    size={'xs'}
                    // p={3}
                    m={2}
                    boxShadow="md"
                  >
                    +
                  </Button>
                  </Flex>
                  <Experiments experiments={experiments} />
                </Box>
                <Divider borderColor={dividerBg} my={5} width={"95%"} />
                <Flex
                  as="button"
                  onClick={handleLogout}
                  p={2}
                  color={selectableColor}
                  alignItems="center"
                >
                  <FiLogOut />
                  <Text ml={2}>Log out</Text>
                </Flex>
              </Box>
              <Flex mt="auto" flexDir="column" mb={5}>
                <Text
                  color="blue.500"
                  fontSize="lg"
                  fontWeight={"bold"}
                  p={2}
                  onClick={handleOpenFeedbackModal}
                  _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Provide Feedback
                </Text>
              </Flex>
              {/* {currentUser?.email && (
                <Text color={unselectableTextColor} noOfLines={2} fontSize="sm" p={2}>
                  Logged in as: {currentUser.email}
                </Text>
              )} */}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Desktop */}
      <Box
        bg={bgColor}
        pl={3}
        mr={3}
        pb={3}
        pt={2}
        minH="100%"
        position="relative"
        top="0"
        display={{ base: 'none', md: 'flex' }}
      >
        <Flex
          flexDir="column"
          minH="100%"
          minW="200px"
          // justify="space-between"
          // bg={secBgColor}
          // p={4}
          // borderRadius={12}
        >
          <Link to="/"style={{ display: 'flex', alignItems: 'center', height:'10vh'}}>
            <Flex cursor="pointer" maxH="100%" aspectRatio={1} mr={20}>
              <Image
                filter="drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))"
                maxH="80%" src={Logo} alt="Logo" my={"auto"} />
              <Flex flexDirection="column" justifyContent="center" ml="2"> {/* Ensure this height matches the logo height */}
                <Text
                  color={iluvatar_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">iluvatar</Text>
                <Text
                  color={crucible_theme} fontSize="2xl" fontFamily="Comfortaa" lineHeight="1">Crucible</Text>
              </Flex>
            </Flex>
          </Link>

          <Divider borderColor={dividerBg} my={5} width={"95%"} />

          <Box>
            <Flex
            flexDir="row"
            justify="space-between"
            align="center"
            >
            <Text fontSize="xl">Experiments</Text>
            <Button
              variant="primary"
              onClick={() => experimentModal.onOpen()}
              size={'xs'}
              // p={3}
              m={2}
              boxShadow="md"
            >
              +
            </Button>
            </Flex>
            <Experiments experiments={experiments} />
          </Box>
          {currentUser?.email && (
            <Flex mt="auto" flexDir="column" mb={5}>
              <Divider borderColor={dividerBg} my={3} width={"95%"} />
              <Switch mx="auto" onChange={toggleColorMode} value={colorMode}>
                <MoonIcon height={"100%"} mb={2}/>
              </Switch>
              <Text
                color="blue.500"
                fontSize="sm"
                p={2}
                mx={"auto"}
                onClick={handleOpenFeedbackModal}
                _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Provide Feedback
              </Text>
            </Flex>
          )}
        <Modal isOpen={isFeedbackModalOpen} onClose={handleCloseFeedbackModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Provide Feedback</ModalHeader>
            <ModalCloseButton />
            <ModalBody minW="400px" minH="400px">
            <Textarea
              value={feedback}
              width="100%"
              minW="400px"
              minH="400px"
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter your feedback"
              size="lg" // make the textarea larger
            />
            </ModalBody>
            <ModalFooter>
              <Button variant="primary" mr={3} onClick={handleFeedback}>
                Submit
              </Button>
              <Button variant="ghost" onClick={handleCloseFeedbackModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AddExperiment isOpen={experimentModal.isOpen} onClose={experimentClicked} />
        </Flex>
      </Box>
    </>
  )
}

export default ExperimentBar
