import ReactDOM from 'react-dom/client'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen'
import CombinedProvider from './contexts/CombinedProvider'
import ReactGA from 'react-ga4'
import { OpenAPI } from './client'
import theme from './theme'
import { StrictMode } from 'react'

import * as Sentry from "@sentry/react";

OpenAPI.BASE = import.meta.env.VITE_API_URL
OpenAPI.TOKEN = async () => {
  return localStorage.getItem('access_token') || ''
}
OpenAPI.HEADERS = async () => {
  return {"experiment-id": localStorage.getItem('experiment_id') || ""}
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(import.meta.env.VITE_FRONTEND_GA_ID)

  Sentry.init({
    dsn: import.meta.env.VITE_FRONTEND_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app.iluvatar.tech\/api/, /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

}

const queryClient = new QueryClient()

const router = createRouter({ routeTree })
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CombinedProvider>
          <RouterProvider router={router} />
        </CombinedProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </StrictMode>,
)
