import { FC, useRef, useState, useEffect } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  VStack,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
  Portal  // Import Portal from Chakra UI
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LogoMap } from '../../utils';

interface ModelDropdownProps {
  models: Array<{
    name: string;
    openai_name: string;
    cost: number;
    parameter_count: string;
    company: string;
  }>;
  selectedModel: string;
  onModelChange: (event: any) => void;
}

const ModelDropdown: FC<ModelDropdownProps> = ({ models, selectedModel, onModelChange }) => {

  const selectableColor = useColorModeValue('ui.light.main', 'ui.dark.main')

  function reorderModelsByCompany(models: ModelDropdownProps["models"]): ModelDropdownProps["models"] {
    // First, create a map to record the first index appearance of each company.
    const companyOrder: Record<string, number> = {};

    models.forEach((model, index) => {
        if (companyOrder[model.company] === undefined) {
            companyOrder[model.company] = index;
        }
    });

    // Now, sort the array based on the initial index of each model's company.
    return models.slice().sort((a, b) => {
        return companyOrder[a.company] - companyOrder[b.company];
    });
  }

  const { isOpen, onOpen,  } = useDisclosure();
  const btnRef = useRef<HTMLElement>(null);
  const [maxMenuHeight, setMaxMenuHeight] = useState('300px'); // Default max height

  useEffect(() => {
    if (btnRef.current) {
      const rect = btnRef.current.getBoundingClientRect();
      const maxHeight = window.innerHeight - rect.bottom - 10; // 10px space from window bottom
      setMaxMenuHeight(`${maxHeight}px`);
    }
  }, [isOpen]);

  models = reorderModelsByCompany(models)
  return (
    <Menu>
      <MenuButton ref={btnRef} onClick={onOpen} as={Button} rightIcon={<ChevronDownIcon />} 
      width="full" 
      textAlign="left" 
      textColor={selectableColor}
      >
        {selectedModel || 'Select model'}
      </MenuButton>
      <Portal>
        <MenuList maxHeight={maxMenuHeight} overflowY="scroll">
          {models.map((model, index) => (
            <MenuItem key={index} minH="48px" onClick={() => onModelChange(model)}>
              <HStack>
                <Image width={"30px"} src={LogoMap(model.company)} alt={model.company} />
                <VStack align="left" spacing={0}>
                  <Text color={selectableColor} fontWeight="bold">{model.name}</Text>
                  <Text fontSize="xs" color="gray.500">{"$" + model.cost + '/MTokens | ' + model.parameter_count + " Parameters"}</Text>
                </VStack>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}

export default ModelDropdown;
