// utils.ts

import type { ApiError } from "./client"
import HuggingFaceLogo from './assets/images/provider_logos/huggingface.svg';
import OpenaiLogo from './assets/images/provider_logos/openai_logo.svg';
import MetaLogo from './assets/images/provider_logos/meta_logo.png';
import MistralLogo from './assets/images/provider_logos/mistral_logo.png';
import MicrosoftLogo from './assets/images/provider_logos/microsoft_logo.png';
import NvidiaLogo from './assets/images/provider_logos/nvidia_logo.svg';
import GoogleLogo from './assets/images/provider_logos/google_logo.png';
import AlibabaLogo from './assets/images/provider_logos/qwen_logo.webp';

/**
 * Returns the color value based on the color mode.
 *
 * @param colorMode - The current color mode ('light' or 'dark').
 * @param colorSet - The specific type of color to use.
 * @returns The appropriate color key based on the color mode.
 */
export function selectColor(colorMode: string, colorType: String): string {
    return colorMode === 'dark' ? 'ui.dark.'+colorType : 'ui.light.'+colorType;
}

export function LogoMap(key: string): string {
  const map: { [key: string]: string } = {
      'openai': OpenaiLogo,
      'meta': MetaLogo,
      'mistral': MistralLogo,
      'microsoft': MicrosoftLogo,
      'nvidia': NvidiaLogo,
      'google': GoogleLogo,
      'qwen': AlibabaLogo
  };
  
  return map[key] !== undefined ? map[key] : HuggingFaceLogo;
}

export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: "Invalid email address",
}

export const namePattern = {
  value: /^[A-Za-z\s\u00C0-\u017F]{1,30}$/,
  message: "Invalid name",
}

export const passwordRules = (isRequired = true) => {
  const rules: any = {
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters",
    },
  }

  if (isRequired) {
    rules.required = "Password is required"
  }

  return rules
}

export const confirmPasswordRules = (
  getValues: () => any,
  isRequired = true,
) => {
  const rules: any = {
    validate: (value: string) => {
      const password = getValues().password || getValues().new_password
      return value === password ? true : "The passwords do not match"
    },
  }

  if (isRequired) {
    rules.required = "Password confirmation is required"
  }

  return rules
}

export const handleError = (err: ApiError, showToast: any) => {
  const errDetail = (err.body as any)?.detail
  let errorMessage = errDetail || "Something went wrong."
  if (Array.isArray(errDetail) && errDetail.length > 0) {
    errorMessage = errDetail[0].msg
  }
  showToast("Error", errorMessage, "error")
}