import { Flex, Button, Text, Spinner, Box, Switch, Heading, useColorModeValue } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MdArrowForward, MdArrowBack, MdArrowDownward } from 'react-icons/md';
import { EvaluationService } from '../../client'
import { Link } from '@tanstack/react-router'
import { useQuery } from 'react-query'
import MilestoneProgressBar from '../../components/Common/MilestoneProgressBar';


export const Route = createFileRoute('/_layout/compare')({
    component: CardComparison,
})

function CardComparison () {

  const {data: evaluation, refetch} = useQuery('evaluate', () => EvaluationService.getEvaluation({experimentId: localStorage.getItem('experiment_id') || ''}), {refetchInterval: (data) => data ? false : 2000, refetchIntervalInBackground: true, enabled: true, refetchOnWindowFocus: false})

  const [showMarkdown, setShowMarkdown] = useState(true);

  const bgColor = useColorModeValue('ui.light.main', 'ui.dark.main')
  const hoverColor = useColorModeValue('ui.light.main5', 'ui.dark.main3')
  const textColor = useColorModeValue('ui.light.light', 'ui.dark.light')

  const handleButtonClick = async (which_option: String) => {
    if (!evaluation) {
        return;
    }
    switch(which_option) {
        case 'left':
            // Send data back
            await EvaluationService.postEvaluation({
                requestBody: {
                    comparison_id: evaluation.comparison_id,
                    winner_id: evaluation.card_1.id
                }
            })
            refetch()
            break;
        case 'right':
            // Send data back
            await EvaluationService.postEvaluation({
              requestBody: {
                  comparison_id: evaluation.comparison_id,
                  winner_id: evaluation.card_2.id
              }
          })
            refetch()
            break;
        case 'neither':
              await EvaluationService.postEvaluation({
                requestBody: {
                    comparison_id: evaluation.comparison_id,
                    winner_id: null
                }
            })
            refetch()
            break;
            // Send data back
        default:
            // Keep this here (and dont use neither as default) in case of UI oddities
            break;
        }
      };

  const milestones = [
    { label: 'Minimum', value: 10 },
    { label: 'Recommended', value: 50 },
    { label: 'Complete', value: 100 }
  ];

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
        handleButtonClick('left');
    } else if (event.key === 'ArrowRight') {
        handleButtonClick('right');
    } else if (event.key == "ArrowDown"){
       handleButtonClick('neither')
    }
  };

  const toggleMarkdown = () => {
    setShowMarkdown(!showMarkdown);
  }

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
  }, [evaluation]);

  return (evaluation ?
    <Flex flexDirection="column" alignItems="center" py={4} width="100%">
      {/* <Text fontSize="xl">Description (optional)</Text> */}
      <Heading size="lg" textAlign={{ base: 'center', md: 'left' }}
        display={{ base: 'inherit', md: 'none' }}
        mb={8}>
        Evaluate
      </Heading>
      <Flex width={"100%"} justifyItems={"center"}>
        <Box width={{ base: '70%', md: '80%' }}>
          <MilestoneProgressBar milestones={milestones} currentValue={evaluation.total_comparison_completed} maxValue={evaluation.total_comparison_count} />
        </Box>
        <Switch my="auto" width={{ base: '40%', md: '20%' }} name="_" onChange={toggleMarkdown} defaultChecked >Markdown</Switch>
      </Flex>
      <Flex my={4} width="95%" justifyContent="center">
      <Box
          overflow="auto"
          display={"block"}
          onClick={() => handleButtonClick('left')}
          bg={bgColor}
          _hover={{ cursor: 'pointer', bg: hoverColor }}
          textColor={textColor}
          borderRadius="lg"
          width="50%" // Example width, adjust as needed
          height="auto"
          p={10}
          mr={2}
          // m={2}
          boxShadow="md"
          style={{
            // overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal' // Allow text wrapping
          }}
        >
          {showMarkdown ?
            <Markdown remarkPlugins={[remarkGfm]}>
              {evaluation.card_1.output}
            </Markdown>
            :
            evaluation.card_1.output
          }
        </Box>
        <Box
          overflow="auto"
          display={"block"}
          onClick={() => handleButtonClick('right')}
          bg={bgColor}
          ml={2}
          _hover={{ cursor: 'pointer', bg: hoverColor }}
          textColor={textColor}
          borderRadius="lg"
          width="50%" // Example width, adjust as needed
          height="auto"
          p={10}
          // m={2}
          boxShadow="md"
          style={{
            // overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal' // Allow text wrapping
          }}
        >
          {showMarkdown ?
            <Markdown remarkPlugins={[remarkGfm]}>
              {evaluation.card_2.output}
            </Markdown>
            :
            evaluation.card_2.output
          }
        </Box>
      </Flex>
      <Flex gap={{base: "2em", md:"5em", lg:"10em"}} justifyContent="center">
        <Button leftIcon={<MdArrowBack />} onClick={() => handleButtonClick('left')} variant="primary" m={1}>Left</Button>
        <Button rightIcon={<MdArrowDownward/>} onClick={() => handleButtonClick('neither')} variant="primary" m={1}>Both Bad</Button>
        <Button rightIcon={<MdArrowForward />} onClick={() => handleButtonClick('right')} variant="primary" m={1}>Right</Button>
      </Flex>
      <Text mt={6} fontSize="sm">You can click on the buttons or use arrow keys to select</Text>
    </Flex>
  :
  ( localStorage.getItem('experiment_live') === 'true' ?
  <Flex flexDirection="column" alignItems="center" p={4} mt={10} width="100%"> <Text fontSize="xl">Submit an <Link to={"/variables"}>experiment configuration</Link> to start evaluating</Text></Flex>
  : <Flex justify="center" align="center" height="100vh" width="full"> <Text fontSize="xl" m={3}>Your experiment is loading</Text>
  <Spinner size="xl" color="ui.main" />
</Flex>)
  );
};

export default CardComparison;
