import { extendTheme } from '@chakra-ui/react'
import { selectColor } from './utils';

const disabledStyles = {
  _disabled: (props: any) => ({
    backgroundColor: selectColor(props.colorMode, 'main'),
  }),
}

const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    ui: {
      light : {
        iluvatar_theme: '#ffd396',
        crucible_theme: '#E93F00',
        b1: '#e0e0e0', // B stands for background, roughly
        b2: '#eeeeee',
        b3: '#d4d4d4',
        // Next five defined here https://paletton.com/#uid=30e0u0krVzOhlJJmFD8yetd-EmE
        main: '#B43100',
        main2: '#E93F00',
        main3: '#FF5D21',
        main4: '#FF7B4A',
        main5: '#FF9A75',
        // main: '#FF9A75',
        // main2: '#FF7B4A',
        // main3: '#FF5D21',
        // main4: '#E93F00',
        // main5: '#B43100',
        secondary: '#FF9A75', // same as main5 for now
        success: '#48BB78',
        danger: '#E53E3E',
        white: '#FFFFFF',
        black: '#000000',
        dark: '#000000',
        light: '#FFFFFF',
        btnText: '#FFFFFF',
        darkSlate: '#4CA100', // aka dark secondary
      },
      dark : {
        iluvatar_theme: '#ffd396',
        crucible_theme: '#E93F00',
        b1: '#111827', // B stands for background, roughly
        b2: '#1f2937',
        b3: '#374151',
        // Next five defined here https://paletton.com/#uid=30e0u0krVzOhlJJmFD8yetd-EmE
        main: '#FFD396', 
        main2: '#FFC168',
        main3: '#FFB143',
        main4: '#FFA11B',
        main5: '#EC8A00',
        secondary: '#FF9A75', // same as main5 for now
        success: '#48BB78',
        danger: '#E53E3E',
        white: '#FFFFFF',
        black: '#000000',
        dark: '#FFFFFF',
        light: '#000000',
        btnText: '#000000',
        darkSlate: '#4CA100', // aka dark secondary
      },
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: selectColor(props.colorMode, 'b2'),
        color: selectColor(props.colorMode, 'dark'),
      },
    }),
  },
  components: {
    Button: {
      variants: {
        primary: (props: any) => ({
          backgroundColor: selectColor(props.colorMode, 'main'),
          color: selectColor(props.colorMode, 'btnText'),
          _hover: {
            backgroundColor: selectColor(props.colorMode, 'main5'),
          },
          _disabled: {
            ...disabledStyles,
            _hover: {
              ...disabledStyles,
            },
          },
        }),
        danger: (props: any) => ({
          backgroundColor: selectColor(props.colorMode, 'danger'),
          color: selectColor(props.colorMode, 'white'),
          _hover: {
            backgroundColor: '#E32727',
          },
        }),
      },
    },
    Tabs: {
      variants: {
        enclosed: (props: any) => ({
          tab: {
            color: selectColor(props.colorMode, 'main'),
            _selected: {
              color: selectColor(props.colorMode, 'main3'),
            },
          },
        }),
      },
    },
    // Input: {
    //   baseStyle: (props: any) => ({
    //     field: {
    //       borderColor: selectColor(props.colorMode, 'main1'), // Outline color
    //       _placeholder: {
    //         color: selectColor(props.colorMode, 'main1'), // Placeholder text color
    //       },
    //       _hover: {
    //         borderColor: selectColor(props.colorMode, 'main4'), // Hover state outline color
    //       },
    //       _focus: {
    //         borderColor: selectColor(props.colorMode, 'main5'), // Focus state outline color
    //         boxShadow: `0 0 0 1px ${selectColor(props.colorMode, 'main5')}`, // Optional: focus shadow
    //       },
    //     }
    //   })
    // },
  },
})

export default theme
