import React from 'react'
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useRouter } from '@tanstack/react-router'

import { ApiError, ExperimentsService } from '../../client'
import useCustomToast from '../../hooks/useCustomToast'
import { None } from 'framer-motion'

interface SubmitExperimentProps {
  isOpen: boolean
  onClose: () => void
  canSubmit: () => boolean
}

const SubmitExperiment: React.FC<SubmitExperimentProps> = ({ isOpen, onClose, canSubmit }) => {
  const showToast = useCustomToast()
  const router = useRouter()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<None>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
    },
  })

  const submitExperiment = async () => {
    await ExperimentsService.submitExperiment({ id: localStorage.getItem('experiment_id') || '' })
    const experiment_live = await ExperimentsService.getExperimentStatus({id: localStorage.getItem('experiment_id') || ''})
    localStorage.setItem('experiment_live', experiment_live.toString())
  }

  const mutation = useMutation(submitExperiment, {
    onSuccess: () => {
      showToast('Success!', 'Experiment submitted successfully.', 'success')
      reset()
      onClose()
      router.navigate({ to: '/compare' })
    },
    onError: (err: ApiError) => {
      const errDetail = err.body.detail
      showToast('Something went wrong.', `${errDetail}`, 'error')
    }
  })

  const onSubmit: SubmitHandler<None> = () => {
    mutation.mutate()
  }

  const background = useColorModeValue('ui.light.b2', 'ui.dark.b2')

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'sm', md: 'md' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          bg={background}
          >
          <ModalHeader>Submit Experiment</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {canSubmit() ?
            <Text>You can not change the experiment once it has been submitted.</Text> 
            :
            <>
            <Text>You need at least two models or two prompts.</Text>
            <br></br>
            <Text>Every possible pairing of prompt-model is an experiment condition and you need at least two conditions to compare.</Text>
            </>
            }
          </ModalBody>

          <ModalFooter gap={3}>
            {canSubmit() ?
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Submit
            </Button> : <></>
            }
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SubmitExperiment
