/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExperimentCreate } from '../models/ExperimentCreate';
import type { ExperimentOut } from '../models/ExperimentOut';
import type { ExperimentsOut } from '../models/ExperimentsOut';
import type { ExperimentUpdate } from '../models/ExperimentUpdate';
import type { Message } from '../models/Message';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExperimentsService {

    /**
     * Read Experiments
     * Retrieve experiments.
     * @returns ExperimentsOut Successful Response
     * @throws ApiError
     */
    public static readExperiments({
        skip,
        limit = 100,
    }: {
        skip?: number,
        limit?: number,
    }): CancelablePromise<ExperimentsOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/experiments/',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Experiment
     * Create new experiment.
     * @returns ExperimentOut Successful Response
     * @throws ApiError
     */
    public static createExperiment({
        requestBody,
    }: {
        requestBody: ExperimentCreate,
    }): CancelablePromise<ExperimentOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/experiments/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Experiment
     * Get experiment by ID.
     * @returns ExperimentOut Successful Response
     * @throws ApiError
     */
    public static readExperiment({
        id,
    }: {
        id: string,
    }): CancelablePromise<ExperimentOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/experiments/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Experiment
     * Delete an experiment.
     * @returns Message Successful Response
     * @throws ApiError
     */
    public static deleteExperiment({
        id,
    }: {
        id: string,
    }): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/experiments/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Experiment
     * @returns ExperimentOut Successful Response
     * @throws ApiError
     */
    public static updateExperiment({
        experimentId,
        requestBody,
    }: {
        experimentId: string,
        requestBody: ExperimentUpdate,
    }): CancelablePromise<ExperimentOut> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/experiments/{id}',
            query: {
                'experiment_id': experimentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Experiment
     * Submit an experiment.
     *
     * Also creates a global lock, queue, and datastore to be shared variables
     * during multithreading.
     * @returns Message Successful Response
     * @throws ApiError
     */
    public static submitExperiment({
        id,
    }: {
        id: string,
    }): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/experiments/submit/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Experiment Status
     * Get experiment status.
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static getExperimentStatus({
        id,
    }: {
        id: string,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/experiments/status/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
