import React from 'react'
import { Button, Container, Text, useColorModeValue } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'

const NotFound: React.FC = () => {
  const mainColor = useColorModeValue("ui.light.white", "ui.dark.white")
  return (
    <>
      <Container
        h="100vh"
        alignItems="stretch"
        justifyContent="center"
        textAlign="center"
        maxW="sm"
        centerContent
      >
        <Text
          fontSize="8xl"
          color={mainColor}
          fontWeight="bold"
          lineHeight="1"
          mb={4}
        >
          404
        </Text>
        <Text fontSize="md">Oops!</Text>
        <Text fontSize="md">Page not found.</Text>
        <Button
          as={Link}
          to="/"
          color={mainColor}
          borderColor={mainColor}
          variant="outline"
          mt={4}
        >
          Go back
        </Button>
      </Container>
    </>
  )
}

export default NotFound
