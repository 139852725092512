import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Link,
  Text,
  useBoolean,
} from "@chakra-ui/react"
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Link as RouterLink,
  createFileRoute,
  redirect,
} from "@tanstack/react-router"
import { type SubmitHandler, useForm } from "react-hook-form"

import LogoText from '../components/Common/LogoText'
import type { UserCreate } from "../client"
import useAuth, { isLoggedIn } from "../hooks/useAuth"
import { confirmPasswordRules, emailPattern, passwordRules } from "../utils"
import { UsersService } from '../client'

export const Route = createFileRoute("/referral/$referral_id")({
  component: SignUp,
  beforeLoad: async () => {
    if (isLoggedIn()) {
      throw redirect({
        to: "/",
      })
    }
  },
})

interface UserCreateForm extends UserCreate {
  confirm_password: string
}

function SignUp() {
  const [show, setShow] = useBoolean()
  const { login } = useAuth()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<UserCreateForm>({
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues: {
      email: "",
      full_name: "",
      password: "",
      industry: "",
      company: "",
      occupation: "",
      confirm_password: "",
    },
  })

  const { referral_id } = Route.useParams()
  const onSubmit: SubmitHandler<UserCreateForm> = async (data) => {
    try {
      await UsersService.referral({referralId: referral_id, requestBody: data})
      var access_token = {username: data.email, password: data.password}
      await login(access_token)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Flex flexDir={{ base: "column", md: "row" }} justify="center" h="100vh">
        <Container
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          h="100vh"
          maxW="sm"
          alignItems="stretch"
          justifyContent="center"
          gap={4}
          centerContent
        >
          <Box maxH="15vh" mb={10}>
            <LogoText />
          </Box>
          <FormControl id="full_name" isInvalid={!!errors.full_name}>
            <FormLabel htmlFor="full_name" srOnly>
              Full Name
            </FormLabel>
            <Input
              id="full_name"
              minLength={3}
              {...register("full_name", { required: "Full Name is required" })}
              placeholder="Full Name"
              type="text"
            />
            {errors.full_name && (
              <FormErrorMessage>{errors.full_name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="email" isInvalid={!!errors.email}>
            <FormLabel htmlFor="username" srOnly>
              Email
            </FormLabel>
            <Input
              id="email"
              {...register("email", {
                required: "Email is required",
                pattern: emailPattern,
              })}
              placeholder="Email"
              type="email"
            />
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="industry" isInvalid={!!errors.industry}>
            <FormLabel htmlFor="industry" srOnly>
              Industry
            </FormLabel>
            <Input
              id="industry"
              {...register("industry")}
              placeholder="Industry"
              type="text"
            />
            {errors.industry && (
              <FormErrorMessage>{errors.industry.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="company" isInvalid={!!errors.company}>
            <FormLabel htmlFor="company" srOnly>
              Company
            </FormLabel>
            <Input
              id="company"
              {...register("company")}
              placeholder="Company"
              type="text"
            />
            {errors.company && (
              <FormErrorMessage>{errors.company.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="occupation" isInvalid={!!errors.occupation}>
            <FormLabel htmlFor="occupation" srOnly>
              Occupation
            </FormLabel>
            <Input
              id="occupation"
              {...register("occupation")}
              placeholder="Occupation"
              type="text"
            />
            {errors.occupation && (
              <FormErrorMessage>{errors.occupation.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="password" isInvalid={!!errors.password}>
            <FormLabel htmlFor="password" srOnly>
              Password
            </FormLabel>
            <InputGroup>
              <Input
                id="password"
                {...register("password", passwordRules())}
                placeholder="Password"
                type={show ? 'text' : 'password'}
              />
              <InputRightElement
                color="gray.400"
                _hover={{
                  cursor: 'pointer',
                }}
              >
                <Icon
                  onClick={setShow.toggle}
                  aria-label={show ? 'Hide password' : 'Show password'}
                >
                  {show ? <ViewOffIcon /> : <ViewIcon />}
                </Icon>
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            id="confirm_password"
            isInvalid={!!errors.confirm_password}
          >
            <FormLabel htmlFor="confirm_password" srOnly>
              Confirm Password
            </FormLabel>

            <Input
              id="confirm_password"
              {...register("confirm_password", confirmPasswordRules(getValues))}
              placeholder="Repeat Password"
              type={show ? 'text' : 'password'}
            />
            {errors.confirm_password && (
              <FormErrorMessage>
                {errors.confirm_password.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <Button variant="primary" type="submit" isLoading={isSubmitting}>
            Sign Up
          </Button>
          <Text>
            Already have an account?{" "}
            <Link as={RouterLink} to="/login" color="blue.500">
              Log In
            </Link>
          </Text>
        </Container>
      </Flex>
    </>
  )
}

export default SignUp
